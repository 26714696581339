import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import styled from "styled-components"
import { Colors, Sizes } from "./Theme"

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Colors.Default};
  font-family: "Courier", monospace;
  font-size: 0.8em;
  line-height: 1.4;
  padding: 15px;
`

const Main = styled.main`
  display: grid;
  width: 90vw;
  grid-template-columns: auto 1fr 4fr;
  grid-template-rows: auto;
  column-gap: 5%;
  row-gap: 25px;
  justify-content: center;
  align-items: center;
  justify-items: center;

  grid-template-areas: "header image bio";

  @media (max-width: ${Sizes.tablet}) {
    column-gap: 15px;

    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "image header"
      "bio bio";
  }
`

const Layout = ({ children }) => (
  <Container>
    <Main>{children}</Main>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
