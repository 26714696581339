export enum Colors {
  Background = "white",
  Default = "#1e0066",
  Link = "#ca0051",
  Letter = "#ca0051",
  LetterFinal = Default,
}

export enum Sizes {
  mobileS = "320px",
  mobileM = "375px",
  mobileL = "425px",
  tablet = "768px",
  laptop = "1024px",
  laptopL = "1440px",
  desktop = "2560px",
}

export const LetterSize = 25
